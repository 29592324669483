.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
}
.paper {
  padding: 10px 0px;
}
.loadingDC span {
  margin-right: 10px;
}
.agenda-style > td {
  background-color: #ffffff !important;
}
.rbc-month-header,
.rbc-time-header-cell {
  min-height: 40px !important;
}
.rbc-time-header-cell div,
.rbc-month-header div {
  font-size: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rbc-time-gutter {
  min-width: 71.125px;
}
.rdrDefinedRangesWrapper {
  display: none;
}
.react-calendar {
  border-radius: 4px !important;
  width: 350px !important;
  max-width: 100% !important;
  background: white !important;
  font: inherit !important;
  line-height: 1.125em !important;
  border: 0 !important;
  overflow: hidden !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.contain-card-dashboard {
  cursor: pointer;
  border-radius: 8px;
  padding: 20px !important;
  display: flex;
  align-items: center;
}

.contain-card-dashboard:hover {
  box-shadow: 5px 4px 4px 0px #00000040;
  background-color: #31ceff29;
}

.icon-dashboard {
  cursor: pointer;
  border-radius: 50%;
}

.icon-dashboard:hover {
  box-shadow: 0px 0px 10px 0px black;
}

/* #text-dashboard {
  font-size: 16px;
  color: #707070;
  line-height: 1;
  margin-top: 4px;
} */

#text-dashboard:hover {
  color: #000;
  text-shadow: 2px 3px 2px rgba(79, 79, 79, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -webkit-text-stroke: 0.5px white;
}

.MuiTooltip-tooltip {
  color: #fff;
  padding: 4px 8px;
  font-size: 1rem !important;
  max-width: 300px;
  word-wrap: break-word;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
}

.MuiTooltip-popper {
  top: -100px !important;
}

.MuiButton-label {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
